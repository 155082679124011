<template>
	<div class="single-blog person-container" ref="personCont">
		<div class="jessica-experience">
			<div class="jessica-picture-container" v-if="featuredImage?.asset">
				<img
					:src="featuredImage.asset.url"
					:alt="title"
					class="jessica-img featured-image"
				/>
			</div>
			<h1 class="jessica-name" ref="title">{{ title }}</h1>

			<!-- Blog Content -->
			<div class="experience-1">
				<div v-if="content && content.length > 0">
					<div v-for="(block, index) in content" :key="index">
						<!-- Render based on block type -->
						<component :is="getComponentType(block)" v-bind="block">
							{{ block.children ? block.children[0].text : "" }}
						</component>
					</div>
				</div>
				<!-- Image Carousel -->
				<div
					v-if="imageCarousel && imageCarousel.length > 0"
					class="carousel"
					ref="carousel"
				>
					<div
						v-for="image in imageCarousel"
						:key="image.asset._id"
						class="carousel-image"
					>
						<img
							:src="image.asset.url"
							:alt="image.alt || 'Blog image'"
						/>
						<p v-if="image.caption" class="caption">
							{{ image.caption }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="single-blog">
		<!-- Conditional Component -->
		<div v-if="$route.fullPath !== '/work-together'">
			<WorkTogether />
		</div>
	</div>
</template>

<script>
import sanity from "../client";
import WorkTogether from "../components/WorkTogether.vue";

// Importa jQuery e slick-carousel
import $ from "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const query = `*[_type == "blog" && slug.current == $slug][0]{
    _id,
    title,
    slug,
    description,
    content,
    featuredImage {
        asset->{
            _id,
            url
        }
    },
    imageCarousel[] {
        asset->{
            _id,
            url
        },
        caption,
        alt
    }
}`;

export default {
	name: "SingleBlog",
	components: {
		WorkTogether
	},
	data() {
		return {
			loading: true,
			pages: null,
			title: "",
			content: [],
			imageCarousel: [],
			featuredImage: null,
			error: ""
		};
	},
	created() {
		this.fetchPage();
	},
	mounted() {
		this.$nextTick(() => {
			this.initializeCarousel();
		});
	},
	watch: {
		imageCarousel: {
			handler() {
				this.$nextTick(() => {
					this.initializeCarousel();
				});
			},
			immediate: true
		}
	},
	methods: {
		fetchPage() {
			this.error = this.pages = null;
			this.loading = true;
			sanity.fetch(query, { slug: this.$route.params.slug }).then(
				(pages) => {
					this.loading = false;
					this.pages = pages;
					this.title = pages.title;
					this.content = pages.content;
					this.imageCarousel = pages.imageCarousel;
					this.featuredImage = pages.featuredImage;
				},
				(err) => {
					console.error(err);
					this.error = "Failed to fetch data";
				}
			);
		},
		getComponentType(block) {
			if (block._type === "block") {
				switch (block.style) {
					case "h1":
						return "h1";
					case "h2":
						return "h2";
					case "h3":
						return "h3";
					case "h4":
						return "h4";
					case "blockquote":
						return "blockquote";
					default:
						return "p";
				}
			}
			return "div";
		},
		initializeCarousel() {
			if (this.imageCarousel && this.imageCarousel.length > 0) {
				$(this.$refs.carousel).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					fade: true,
					arrows: false,
					dots: true,
					infinite: true,
					responsive: [
						{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				});
			}
		}
	}
};
</script>

<style>
.carousel {
	width: 100%;
	margin-top: 3vw;
}
.carousel-image {
	position: relative;
	width: 100%;
	padding: 0px;
}
.carousel-image img {
	display: block;
	width: 100%;
	height: 30vw;
	max-height: 30vw;
	min-height: 30vw;
	object-fit: cover;
}
.caption {
	text-align: center;
	font-size: 14px;
	color: #555;
	margin-top: 5px;
}
/*.single-blog .jessica-picture-container {
	width: 100vw;
	height: 30vw;
	top: 8vw;
	left: 0;
	margin-bottom: 4vw;
}*/
.single-blog .jessica-name {
	position: static;
	font-size: 4.5vw;
	transform: none;
	margin-top: 9vw;
	max-width: 60vw;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 3vw;
}
.single-blog .jessica-img {
	min-height: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
}
/*.single-blog .jessica-img {
	min-height: 30vw;
	height: 30vw;
	max-height: 30vw;
	object-fit: cover;
}*/
.single-blog .experience-1 {
	margin-top: 0;
	margin-bottom: 12vw;
}
.single-blog .experience-1 h1,
.single-blog .experience-1 h2,
.single-blog .experience-1 h3,
.single-blog .experience-1 h4,
.single-blog .experience-1 h5,
.single-blog .experience-1 h6 {
	font-family: BonVivant;
}
.single-blog .work-together-container {
	background-color: transparent !important;
}
</style>

<style>
.slick-dots {
	bottom: -3vw !important;
}
.slick-dots li button,
.slick-dots li {
	width: 1.2vw !important;
	height: 1.2vw !important;
	padding: 0;
}
.slick-dots li {
	border: 3px solid #e1d6c2 !important;
	border-radius: 50% !important;
	overflow: hidden;
	width: 1.2vw !important;
	height: 1.2vw !important;
}

.slick-dots li button:before {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	width: 50%;
	height: 50%;
	overflow: hidden;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
}
.slick-dots li.slick-active button:before {
	opacity: 1;
	background-color: #544a3f;
}
@media (max-aspect-ratio: 200/200) {
	.carousel-image img {
		object-fit: cover;
		height: 60vw;
		max-height: 60vw;
		min-height: 60vw;
	}
	/* .single-blog .jessica-picture-container {
		height: 40vw;
		max-height: 40vw;
		min-height: 40vw;
		left: 0;
		margin-top: 14vw;
	} */
	.single-blog .jessica-name {
		font-size: 9vw;
		margin-top: 14vw;
		margin-bottom: 10vw;
	}
	.slick-dots {
		bottom: -6vw !important;
	}
	.slick-dots li button,
	.slick-dots li {
		width: 2vw !important;
		height: 2vw !important;
	}
	.slick-dots li {
		width: 2vw !important;
		height: 2vw !important;
	}
	.carousel {
		margin-top: 6vw;
	}
}
</style>
